import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormikProps, FieldArray, FormikProvider } from 'formik';
import { InstanceType, Meta } from "../../../redux/api/api.types";
import Label from "../../../components/Label/Label";
import HelperText from "../../../components/HelperText/HelperText";
import { HelperTextVariant, UIComponentSizeVarinats } from "../../../utils/enum/enum";

type EncodingMethodsProps = {
  formik: FormikProps<InstanceType>;
};

const encodingList = [
  { key: "NID64", value: "NID64" },
  { key: "NID32", value: "NID32" },
  { key: "SGTIN96", value: "SGTIN96" },
  { key: "LOOKUP", value: "LOOKUP" },
];

export default function EncodingMethods({ formik }: EncodingMethodsProps) {
  const [maxLimitMessage, setMaxLimitMessage] = useState("");
  const [isSGTIN96, setIsSGTIN96] = useState(false);

  const allEncodinglength = formik.values.subscription_metadata.encoding.length;

  const handleSelectChange = (event: { target: { value: string[] } }) => {
    const { value } = event.target;
    const newEncodingMethods = value.map((v) => {
      // Find existing encoding with the same method
      // get stgin meta -> reason for this to keep already stgin96 code if user select other encoding. This is being used in handleSelectChange
      // inside newEncodingMethods const
      const existing = formik.values.subscription_metadata.encoding.find(enc => enc.method === v);
      return {
        method: v,
        meta: existing?.meta ?? (v === "SGTIN96" ? { code: "" } : {})
      };
    });

    formik.setFieldValue("subscription_metadata.encoding", newEncodingMethods);

    const oldEncoding = value.join(",");
    formik.setFieldValue("encoding", oldEncoding);

    if (value.length >= 4) {
      setMaxLimitMessage("Maximum limit for encoding reached.");
      setIsSGTIN96(true);
    } else {
      setMaxLimitMessage("");
      setIsSGTIN96(false);
    }
  };

  const handleMenuItemClick = (optionValue: string) => {
    const currentEncoding = formik.values.subscription_metadata.encoding.map((enc) => enc.method);
    const newEncoding = currentEncoding.includes(optionValue)
      ? currentEncoding.filter((value) => value !== optionValue)
      : [...currentEncoding, optionValue];

    handleSelectChange({ target: { value: newEncoding } });
  };

  useEffect(() => {
    if (allEncodinglength !== undefined) {
      if (allEncodinglength >= 4) {
        setMaxLimitMessage("Maximum limit for encoding reached.");
        setIsSGTIN96(true);
      } else {
        setMaxLimitMessage("");
        setIsSGTIN96(false);
      }
    }
  }, [allEncodinglength, formik.values.subscription_metadata.encoding]);

  return (
    <Grid item xs={6} sx={{ pb: 2 }}>
      <Label text="Encoding method" />
      <Select
        fullWidth
        labelId="encoding-label"
        multiple
        name="subscription_metadata.encoding"
        id="subscription_metadata_encoding"
        value={formik.values.subscription_metadata.encoding.map((enc) => enc.method)}
        renderValue={(selected) => selected.join(", ")}
        inputProps={{ "aria-label": "Without label" }}
        error={
          formik.touched.subscription_metadata?.encoding &&
          Boolean(formik.errors.subscription_metadata?.encoding)
        }
      >
        {encodingList.map((option) => (
          <MenuItem key={option.key} value={option.value}
            onClick={() => handleMenuItemClick(option.value)}
            disabled={isSGTIN96 && formik.values.subscription_metadata.encoding.map((enc) => enc.method).indexOf(option.value) === -1}
          >
            <Checkbox
              checked={formik.values.subscription_metadata.encoding.map((enc) => enc.method).indexOf(option.value) > -1}
              disabled={formik.values.subscription_metadata.encoding.map((enc) => enc.method).indexOf(option.value) === -1 && isSGTIN96}
            />
            <ListItemText primary={option.value} />
          </MenuItem>
        ))}
      </Select>
      {formik.errors.subscription_metadata?.encoding && typeof formik.errors.subscription_metadata.encoding === 'string' && (
        <HelperText
          text={formik.errors.subscription_metadata?.encoding as string}
        />
      )}

      {maxLimitMessage && (
        <HelperText
          text={maxLimitMessage}
          variant={HelperTextVariant.STANDARD}
        />
      )}

      {formik.values.subscription_metadata.encoding
        .map((item, originalIndex) => ({ ...item, originalIndex }))
        .filter((enc) => enc.method === "SGTIN96")
        .map((item) => (
          <FormikProvider key={item.originalIndex} value={formik}>
            <FieldArray
              name={`subscription_metadata.encoding[${item.originalIndex}].meta.code`}
              render={() => (
                <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                  <TextField
                    value="STGIN96"
                    disabled
                    size={UIComponentSizeVarinats.SMALL}
                  />
                  <TextField
                    name={`subscription_metadata.encoding[${item.originalIndex}].meta.code`}
                    value={item.meta.code}
                    onChange={formik.handleChange}
                    placeholder="Company Code"
                    size={UIComponentSizeVarinats.SMALL}
                    sx={{ ml: 1 }}
                    error={
                      formik.touched.subscription_metadata?.encoding?.[item.originalIndex]?.meta?.code &&
                      Boolean(
                        (
                          (formik.errors.subscription_metadata?.encoding?.[item.originalIndex] as Meta)?.meta?.code as string
                        )
                      )
                    }
                  />
                  <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
                    {(formik.errors.subscription_metadata?.encoding?.[item.originalIndex] as {
                      meta: {
                        code: string
                      }
                    })?.meta?.code && (
                        <FormHelperText
                          variant={HelperTextVariant.STANDARD}
                          sx={{ mt: 0 }}
                        >
                          {
                            (
                              (formik.errors.subscription_metadata?.encoding?.[item.originalIndex] as {
                                meta: {
                                  code: string
                                }
                              }
                              )
                                ?.meta?.code as string)
                          }
                        </FormHelperText>
                      )}
                  </Box>
                  <Button
                    type="button"
                    onClick={() => {
                      const updatedEncoding = formik.values.subscription_metadata.encoding.filter(
                        (_, i) => i !== item.originalIndex
                      );

                      formik.setFieldValue("subscription_metadata.encoding", updatedEncoding);
                      formik.setFieldValue("encoding", updatedEncoding.map((enc) => enc.method).join(","));

                      if (updatedEncoding.length < 4) {
                        setMaxLimitMessage("");
                        setIsSGTIN96(false);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              )}
            />

          </FormikProvider>
        ))}
      {formik.values.subscription_metadata.encoding?.map((enc) => enc.method)?.includes("SGTIN96") && (
        <Button
          type="button"
          disabled={isSGTIN96}
          onClick={() => {
            const updatedEncoding = [
              ...formik.values.subscription_metadata.encoding,
              { method: "SGTIN96", meta: { code: "" } }
            ];

            if (allEncodinglength >= 4) {
              setMaxLimitMessage("Maximum limit for encoding reached.");
              setIsSGTIN96(true);
            } else {
              setMaxLimitMessage("");
              setIsSGTIN96(false);
              formik.setFieldValue("subscription_metadata.encoding", updatedEncoding);
              formik.setFieldValue("encoding", updatedEncoding.map((enc) => enc.method).join(","));
            }
          }}
        >
          Add MORE SGTIN96
        </Button>
      )}
    </Grid>
  );
}
