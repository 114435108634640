// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import React from "react";
import MemoryIcon from "@mui/icons-material/Memory";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";

export const sideAdminNavLinks = [
  {
    urlLink: "/",
    textLink: "Instances",
    menuKey: "instances",
    icon: <MemoryIcon />,
    isSub: false,
  },
  {
    urlLink: "/customer",
    textLink: "Sales Orgs",
    menuKey: "customer",
    icon: <GroupIcon />,
    isSub: false,
  },
  {
    urlLink: "/users",
    menuKey: "users",
    textLink: "Users",
    icon: <ManageAccountsIcon />,
    isSub: false,
  },
  {
    urlLink: "/advanced-tools",
    menuKey: "advancedTools",
    textLink: "Advanced tools",
    icon: <SettingsInputAntennaIcon />,
    isSub: true,
  },
];
