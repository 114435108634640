import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ReaderTypes from "./ReaderTypes/ReaderTypes";
import PasswordResetView from "./Passwords/PasswordResetView";
import Login from "./Login";
import TokenVerification from "./TokenVerification";
import { setUserAuth } from "../redux/userSlice";
import { useAppSelector } from "../hooks/storeHooks";
import SideBar from "./SideBar";
import Header from "../components/Header";
import InstanceManagement from "./InstanceManagement/InstanceManagement";
import AddEditInstance from "./InstanceManagement/AddEditInstance";
import CustomerManagement from "./CustomerManagement/CustomerManagement";
import AdvancedTools from "./AdvancedTools/AdvancedTools";
import UserManagement from "./UserManagement/UserManagement";
import UserProfile from "./UserProfile/UserProfile";
import ReaderApp from "./ReaderApp/ReaderApp";
import RedirectPage from "./FrontendRedirect/RedirectPage";
import ChangePassword from "./Passwords/ChangePassword";
import {
  IsUserAuthenticated,
  IsUserUnAuthenticated,
} from "../components/UserAuth/UserAuth";
import PasswordReset from "./Passwords/PasswordReset";

export type ViewsRouterStyleProps = Record<string, never>;
const drawerWidth = 15;
const Wrapper = styled("section", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isLoggedIn",
})<{
  open?: boolean;
  isLoggedIn?: boolean;
}>(({ theme, open, isLoggedIn }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: theme.spacing(3),
  ...(open && {
    width: `calc(100% - ${drawerWidth}rem)`,
    marginLeft: `${drawerWidth}rem`,
    padding: theme.spacing(4),
  }),
  ...(!isLoggedIn && {
    width: "100%",
    marginLeft: "0%",
  }),
}));

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isLoggedIn?: boolean;
}
const TopBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isLoggedIn",
})<AppBarProps>(({ theme, open, isLoggedIn }) => ({
  backgroundColor: "transparent",
  position: "relative",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& svg": {
    color: "#000",
  },
  ...(open && {
    width: `calc(100% - ${drawerWidth}rem)`,
    marginLeft: `${drawerWidth}rem`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!isLoggedIn && {
    width: "100%",
    marginLeft: "0%",
  }),
}));

function ViewsRouter() {
  const { isLoggedIn, isAdmin } = useAppSelector(setUserAuth);
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TopBar position="fixed" open={open} isLoggedIn={isLoggedIn}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </TopBar>

      {isLoggedIn &&
        (isAdmin ? (
          <SideBar open={open} handleClose={handleDrawerClose} />
        ) : null)}
      <Wrapper open={open} isLoggedIn={isLoggedIn}>
        <Main>
          <Routes>
            <Route element={<IsUserAuthenticated />}>
              <Route path="/" element={<InstanceManagement />} />
              <Route path="/add-edit-instance" element={<AddEditInstance />} />
              <Route path="/customer" element={<CustomerManagement />} />
              <Route path="advanced-tools" element={<AdvancedTools />} />
              <Route path="/reader-type" element={<ReaderTypes />} />
              <Route path="/reader-app" element={<ReaderApp />} />
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/users" element={<UserManagement />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/external-link" element={<RedirectPage />} />
            </Route>
            <Route element={<IsUserUnAuthenticated />}>
              <Route path="/login" element={<Login />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/verification" element={<TokenVerification />} />
              <Route
                path="/forgot-password/:uuid"
                element={<PasswordResetView />}
              />
              <Route path="*" element={<Navigate replace to="/login" />} />
            </Route>
          </Routes>
        </Main>
      </Wrapper>
    </>
  );
}

export default ViewsRouter;
