import React, { useState } from "react";
import { useFormik } from "formik";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { extensionValidationSchema } from "../../../utils/validations/validations";
import {
  ComponentSize,
  UIComponentColorVariants,
  ApplicationTypes,
  UIComponentSizeVarinats,
} from "../../../utils/enum/enum";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { useGetInstanceByIdQuery } from "../../../redux/api/instanceApi";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";
import customTheme from "../../../theme/customTheme";
import {
  useGetExtensionImagesQuery,
  useAssignExtensionMutation,
  useLazyGetExtensionInInstanceUpdateQuery,
} from "../../../redux/api/extensionApi";
import { useGetBackendVersionQuery } from "../../../redux/api/systemApi";
import {
  ExtensionResults,
  DeployedIntegrations,
  Integrations as ExtensionIntegrationType,
  DeployedExtensionsTagInfo,
} from "../../../redux/api/api.types";
import Integrations from "./Integraions";
import Extensions from "./Extensions";
import FrontendApplication from "./FrontendApplication";
import BackendApplication from "./BackendApplication";
import { InitialValues } from "./integrationFormTypes";

type AddEditExtensionProps = {
  id: string;
  isOpen: boolean;
  handleCloseDialog: () => void;
};

const initialValues: InitialValues = {
  extensions: [{ extension_name: "", tag: "", name: "" }],
  integrations: [{ integration_name: "", tag: "", name: "" }],
  frontendApplication: { frontendApplication_name: "", tag: "", name: "" },
  backendApplication: { backendApplication_name: "", tag: "", name: "" },
};

export default function AddExtension({
  id,
  isOpen,
  handleCloseDialog,
}: AddEditExtensionProps) {
  const navigate = useNavigate();
  const { data: version } = useGetBackendVersionQuery();
  const [isUpdateExtensions, setIsUpdateExtension] = useState(false);
  const { data: extensionData } = useGetExtensionImagesQuery();
  const [assignExtension] = useAssignExtensionMutation();
  const [extensionInstanceUpdateTrigger] =
    useLazyGetExtensionInInstanceUpdateQuery();
  const { data: Instance } = useGetInstanceByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  // disable state for update

  const disableUpdateState = () => {
    if (Instance?.is_launched && Instance?.status === "paused") {
      return true;
    }
    if (!Instance?.is_launched) {
      return true;
    }
    return false;
  };

  // below are all assigned tag data
  const integrationsData =
    Instance?.assigned_instance_tags?.extensions?.integrations;

  const verticalData = Instance?.assigned_instance_tags?.extensions?.verticals;

  const allApplications = Instance?.assigned_instance_tags?.applications;

  const frontendApplicationData = allApplications?.[ApplicationTypes.FRONTEND];

  const backendApplicationData = allApplications?.[ApplicationTypes.BACKEND];

  const { enqueueSnackbar } = useSnackbar();
  const { createErrorMessage } = useCreateErrorMessage();

  //for deployed extensions and integrartions
  const deployedIntegrations =
    Instance?.deployed_instance_tags?.extensions?.integrations;
  const deployedExtensions =
    Instance?.deployed_instance_tags?.extensions?.verticals;

  const deployedFrontendApplication =
    Instance?.deployed_instance_tags?.applications?.[ApplicationTypes.FRONTEND];
  const deployedBackendApplication =
    Instance?.deployed_instance_tags?.applications?.[ApplicationTypes.BACKEND];

  // Array to store the found extensions
  const foundIntegration: ExtensionResults[] = [];
  const foundExtension: ExtensionResults[] = [];
  // const foundFrontendApplication: ExtensionResults = {};

  // Iterate over each extension in verticalData using Object.keys()
  Object.keys(verticalData || {})?.forEach((verticalName) => {
    // Find the corresponding integrations in extensionData
    const correspondingExtension = extensionData?.results?.find(
      (extension) => extension.name === verticalName
    );

    if (correspondingExtension) {
      foundExtension.push(correspondingExtension);
    }
  });

  // Iterate over each integration in integrationsData using Object.keys()
  Object.keys(integrationsData || {})?.forEach((integrationName) => {
    // Find the corresponding integrations in extensionData
    const correspondingExtension = extensionData?.results?.find(
      (extension) => extension.name === integrationName
    );

    if (correspondingExtension) {
      foundIntegration.push(correspondingExtension);
    }
  });

  // Populate formk with found extension and integrations
  if (id && Instance && Instance?.assigned_instance_tags) {
    initialValues.integrations = foundIntegration?.map((extension) => ({
      integration_name: extension.name,
      tag: integrationsData?.[extension.name]?.tag_id || "",
      name: integrationsData?.[extension.name]?.tag_name || "",
    }));

    initialValues.extensions = foundExtension?.map((vertical) => ({
      extension_name: vertical.name,
      tag: verticalData?.[vertical.name]?.tag_id || "",
      name: verticalData?.[vertical.name]?.tag_name || "",
    }));

    initialValues.frontendApplication.frontendApplication_name =
      ApplicationTypes.FRONTEND;
    initialValues.frontendApplication.tag =
      frontendApplicationData?.tag_id || "";
    initialValues.frontendApplication.name =
      frontendApplicationData?.tag_name || "";

    initialValues.backendApplication.backendApplication_name =
      ApplicationTypes.BACKEND;
    initialValues.backendApplication.tag = backendApplicationData?.tag_id || "";
    initialValues.backendApplication.name =
      backendApplicationData?.tag_name || "";
  }

  const handleExtensionUpdateInstance = () => {
    extensionInstanceUpdateTrigger({ id })
      .unwrap()
      .then(() => {
        enqueueSnackbar("Instance updated with extension.", {
          variant: "success",
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        createErrorMessage({
          error,
          message: "Failed to update instance with extension.",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: extensionValidationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const extensionTags = values.extensions
        .filter((item) => item.tag !== "")
        .map((extension) => extension.tag);
      // Extract tags from integrations
      const integrationTags = values.integrations
        .filter((item) => item.tag !== "")
        .map((integration) => integration.tag);
      // Extract tags from frontend application
      const frontendApplicationTag = values.frontendApplication.tag;
      // Extract tags from backend application

      const backendApplicationTag = values.backendApplication.tag;

      // Combine tags from both arrays
      const allTags = [
        backendApplicationTag,
        frontendApplicationTag,
        ...extensionTags,
        ...integrationTags,
      ].filter((tag) => tag !== "");

      const dataToSubmit = { tag_ids: allTags };
      setIsUpdateExtension(true);
      assignExtension({ dataToSubmit, id })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Extensions assigned to the instance.", {
            variant: "success",
            autoHideDuration: 2000,
          });
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Failed to add extension.",
          });
        });
    },
  });

  // below we check if extension and integrations or applications are empty

  function isEmptyObject(
    obj: DeployedIntegrations | DeployedExtensionsTagInfo | undefined
  ) {
    return Object?.keys(obj || {})?.length === 0;
  }

  // here we check if there is a new build available, we check this inside the assigned_instance_tags
  function isNewBuild(
    extensionName: string,
    extensionsData: ExtensionIntegrationType | undefined
  ) {
    const buildAvailable =
      extensionsData?.[extensionName]?.new_build_available ?? undefined;
    return buildAvailable;
  }

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title="Manage Versions And Extensions"
      size={ComponentSize.Lg}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ pb: 2 }}>
                <Typography variant="h5">Instance Core Version</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography>{version && version.version}</Typography>
            </Grid>
          </Grid>

          {/* below applications for frontend and backend */}
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Divider>
              <Chip
                label="Applications"
                color={UIComponentColorVariants.INFO}
                size={UIComponentSizeVarinats.SMALL}
              />
            </Divider>
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h5">Frontend Applications</Typography>
          </Grid>

          <Grid item xs={12} sx={{ pb: 2 }}>
            {isEmptyObject(deployedFrontendApplication) ? (
              <Typography variant="body1">
                No frontend application added to the instance.
              </Typography>
            ) : (
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      mr: 0.5,
                      ml: 0.5,
                      position: "relative",
                      top: "0.3rem",
                    }}
                  >
                    {ApplicationTypes.FRONTEND}:{" "}
                    {deployedFrontendApplication?.tag_name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  {isNewBuild(ApplicationTypes.FRONTEND, allApplications) ? (
                    <>
                      <Box
                        component="span"
                        sx={{
                          mr: 0.5,
                          ml: 0.5,
                          position: "relative",
                          top: "0.3rem",
                        }}
                      >
                        <InfoOutlinedIcon
                          color={UIComponentColorVariants.INFO}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: customTheme.palette.info.main, ml: 1 }}
                      >
                        New version available!
                      </Typography>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
          <FrontendApplication
            formik={formik}
            extensionData={extensionData}
            deployedTagName={deployedFrontendApplication?.tag_name}
          />

          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h5">Backend Applications</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            {isEmptyObject(deployedFrontendApplication) ? (
              <Typography variant="body1">
                No backend application added to the instance.
              </Typography>
            ) : (
              <Grid container>
                <Grid item xs={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      mr: 0.5,
                      ml: 0.5,
                      position: "relative",
                      top: "0.3rem",
                    }}
                  >
                    {ApplicationTypes.BACKEND}:{" "}
                    {deployedBackendApplication?.tag_name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  {isNewBuild(ApplicationTypes.BACKEND, allApplications) ? (
                    <>
                      <Box
                        component="span"
                        sx={{
                          mr: 0.5,
                          ml: 0.5,
                          position: "relative",
                          top: "0.3rem",
                        }}
                      >
                        <InfoOutlinedIcon
                          color={UIComponentColorVariants.INFO}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ color: customTheme.palette.info.main, ml: 1 }}
                      >
                        New version available!
                      </Typography>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
          <BackendApplication
            formik={formik}
            extensionData={extensionData}
            deployedTagName={deployedBackendApplication?.tag_name}
          />

          <Grid item xs={12} sx={{ pb: 2, mt: "1rem" }}>
            <Divider>
              <Chip
                label="Extensions & Integrations"
                color={UIComponentColorVariants.INFO}
                size={UIComponentSizeVarinats.SMALL}
              />
            </Divider>
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h5">Extensions</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pb: 2 }}>
            {isEmptyObject(deployedExtensions) ? (
              <Typography variant="body1">
                No extension added to the instance.
              </Typography>
            ) : (
              Object.entries(deployedExtensions || {})?.map(([key, value]) => (
                <Grid container key={key}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      sx={{
                        mr: 0.5,
                        ml: 0.5,
                        position: "relative",
                        top: "0.3rem",
                      }}
                    >
                      {key}: {`(${(value as { tag_name: string })?.tag_name})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {isNewBuild(key, verticalData) ? (
                      <>
                        <Box
                          component="span"
                          sx={{
                            mr: 0.5,
                            ml: 0.5,
                            position: "relative",
                            top: "0.3rem",
                          }}
                        >
                          <InfoOutlinedIcon
                            color={UIComponentColorVariants.INFO}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ color: customTheme.palette.info.main, ml: 1 }}
                        >
                          New version available!
                        </Typography>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
          <Extensions
            formik={formik}
            extensionData={extensionData}
            foundExtensionLength={foundExtension.length}
          />
          {/* below is integrations */}
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="h5">Integrations</Typography>
          </Grid>

          <Grid item xs={12} sx={{ pb: 2 }}>
            {isEmptyObject(deployedIntegrations) ? (
              <Typography variant="body1">
                No Integration applied to the instance.
              </Typography>
            ) : (
              Object.entries(deployedIntegrations || {}).map(([key, value]) => (
                <Grid container key={key}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      sx={{
                        mr: 0.5,
                        ml: 0.5,
                        position: "relative",
                        top: "0.3rem",
                      }}
                    >
                      {key}: {`(${(value as { tag_name: string })?.tag_name})`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {isNewBuild(key, integrationsData) ? (
                      <>
                        <Box
                          component="span"
                          sx={{
                            mr: 0.5,
                            ml: 0.5,
                            position: "relative",
                            top: "0.3rem",
                          }}
                        >
                          <InfoOutlinedIcon
                            color={UIComponentColorVariants.INFO}
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ color: customTheme.palette.info.main, ml: 1 }}
                        >
                          New version available!
                        </Typography>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
          <Integrations
            formik={formik}
            extensionData={extensionData}
            foundIntegrationLength={foundIntegration.length}
          />

          <ActionDialog onClose={handleCloseDialog} />
        </Grid>
        {isUpdateExtensions && (
          <ConfirmationDialog
            icon={<InfoIcon color={UIComponentColorVariants.INFO} />}
            isOpen={isUpdateExtensions}
            onClose={() => {
              setIsUpdateExtension(false);
              handleCloseDialog();
              navigate("/");
            }}
            title="Please apply the changes to get instance updated"
            confirmText="UPDATE NOW"
            closeText="SAVE FOR LATER"
            onConfirm={() => {
              handleExtensionUpdateInstance();
              setIsUpdateExtension(false);
              handleCloseDialog();
              navigate("/");
            }}
            disableUpdateState={disableUpdateState()}
          >
            Do you want to update the instance now or save for later?
          </ConfirmationDialog>
        )}
      </form>
    </CommonDialog>
  );
}
