import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PinIcon from "@mui/icons-material/Pin";
import InputAdornment from "@mui/material/InputAdornment";
// or
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import customTheme from "../../theme/customTheme";
import { useCreateErrorMessage } from "../../hooks/useCreateErrorMessage";
import { setTokens } from "../../redux/tokenSlice";
import { setUser } from "../../redux/userSlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { useUserAuthTokenMutation } from "../../redux/api/userApi";
import { tokenValidationSchema } from "../../utils/validations/validations";

function TokenVerification() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [userAuthToken] = useUserAuthTokenMutation();
  const dispatch = useAppDispatch();
  const { createErrorMessage } = useCreateErrorMessage();

  const formik = useFormik({
    initialValues: {
      verificator: location?.state?.verification_token,
      secret_phrase: "",
    },
    validationSchema: tokenValidationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values, { resetForm }) => {
      userAuthToken(values)
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("Success", {
              variant: "success",
              autoHideDuration: 2000,
            });
            resetForm();
            dispatch(
              setTokens({
                accessToken: payload.access,
                refreshToken: payload.refresh,
              })
            );
            dispatch(
              setUser({
                user: payload.user,
                isLoggedIn: !!(payload.user.email && payload.user.username),
                isAdmin: !!(payload.user.email && payload.user.username),
              })
            );
          }
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Token verification failed.",
          });
          resetForm();
        });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#012169" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="secret_phrase"
              name="secret_phrase"
              label="6 digit number"
              sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
              value={formik.values.secret_phrase}
              onChange={formik.handleChange}
              error={
                formik.touched.secret_phrase &&
                Boolean(formik.errors.secret_phrase)
              }
              helperText={
                formik.touched.secret_phrase && formik.errors.secret_phrase
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PinIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: customTheme.palette.primary.main,
                color: "#fff",
                mt: 3,
                mb: 2,
              }}
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
              startIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="1rem" />
                ) : undefined
              }
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default TokenVerification;
