import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserByIdQuery,
} from "../../../redux/api/userApi";
import { userValidationSchema } from "../../../utils/validations/validations";

type AddEditCustomerProps = {
  id?: string;
  isOpen: boolean;
  handleCloseDialog: () => void;
};
const initialValues = {
  username: "",
  email: "",
  first_name: "",
  last_name: "",
};

export default function AddEditUser({
  id,
  isOpen,
  handleCloseDialog,
}: AddEditCustomerProps) {
  const [createUser] = useCreateUserMutation();
  const { data: user } = useGetUserByIdQuery(id);
  const [updateUser] = useUpdateUserMutation();

  const { createErrorMessage } = useCreateErrorMessage();

  if (id && user) {
    initialValues.username = user?.username;
    initialValues.first_name = user?.first_name || "";
    initialValues.last_name = user?.last_name || "";
    initialValues.email = user?.email;
  } else {
    initialValues.username = "";
    initialValues.first_name = "";
    initialValues.last_name = "";
    initialValues.email = "";
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-expressions
      id
        ? updateUser({ id, patch: { ...values, roles: [] } })
            .unwrap()
            .then((payload) => {
              if (payload) {
                enqueueSnackbar("success", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
                handleCloseDialog();
              }

              navigate("/users");
            })
            .catch((error) => {
              createErrorMessage({
                error,
                message: "Failed to update a user.",
              });
              handleCloseDialog();
            })
        : createUser({ ...values, roles: [] })
            .unwrap()
            .then((payload) => {
              if (payload) {
                enqueueSnackbar("success", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
                handleCloseDialog();
              }
              navigate("/users");
            })
            .catch((error) => {
              createErrorMessage({
                error,
                message: "Failed to update a user.",
              });
              handleCloseDialog();
            });
    },
  });

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={id ? "Edit User" : "Add User"}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="username"
          name="username"
          label="Username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <TextField
          fullWidth
          id="first_name"
          name="first_name"
          label="First name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
        />
        <TextField
          fullWidth
          id="last_name"
          name="last_name"
          label="Last name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
        />
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <ActionDialog onClose={handleCloseDialog} />
      </form>
    </CommonDialog>
  );
}
