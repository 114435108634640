export enum ErrorTypeVariantEnum {
  BAD_REQUEST = "BadRequest",
  VALIDATION_ERROR = "ValidationError",
  PERMISSION_DENIED = "PermissionDenied",
  UNPROCESSABLE_ENTITY = "UnprocessableEntity",
  NOT_IMPLEMENTED_EXCEPTION = "NotImplementedException",
  API_EXCEPTION = "APIException",
  PARSE_ERROR = "ParseError",
  AUTHENTICATION_FAILED = "AuthenticationFailed",
  NOT_AUTHENTICATED = "NotAuthenticated",
  NOT_FOUND = "NotFound",
  METHOD_NOT_ALLOWED = "MethodNotAllowed",
  NOT_ACCEPTABLE = "NotAcceptable",
  UNSUPPORTED_MEDIA_TYPE = "UnsupportedMediaType",
  THROTTLED = "Throttled",
  API_REDIRECT_EXCEPTION = "APIRedirectException",
  API_PERMANENT_REDIRECT_EXCEPTION = "APIPermanentRedirectException",
  API_TEMPORARY_REDIRECT_EXCEPTION = "APITemporaryRedirectException",
  NID_EXCEPTION = "NidException",
  GATEWAY_ERROR = "GatewayError",
}

export type ErrorType = `${ErrorTypeVariantEnum}`;

const defaultErrorMessage = "Something went wrong.";

export function getErrorMessage(error) {
  const errorType = error?.data?.type;
  if (!errorType || typeof errorType !== "string") {
    return defaultErrorMessage;
  }
  switch (errorType) {
    case ErrorTypeVariantEnum.BAD_REQUEST:
      return "The server couldn't process the request due to a client error.";
    case ErrorTypeVariantEnum.VALIDATION_ERROR:
      return "Request validation failed.";
    case ErrorTypeVariantEnum.UNPROCESSABLE_ENTITY:
      return "Request could not be processed due to relational issues.";
    case ErrorTypeVariantEnum.NOT_IMPLEMENTED_EXCEPTION:
      return "Gateway configuration is missing.";
    case ErrorTypeVariantEnum.API_EXCEPTION:
      return "Internal server error.";
    case ErrorTypeVariantEnum.PARSE_ERROR:
      return "Request could not be parsed.";
    case ErrorTypeVariantEnum.AUTHENTICATION_FAILED:
      return "Incorrect credentials.";
    case ErrorTypeVariantEnum.NOT_AUTHENTICATED:
      return "Credentials were not provided.";
    case ErrorTypeVariantEnum.PERMISSION_DENIED:
      return "User doesn’t have the permission.";
    case ErrorTypeVariantEnum.NOT_FOUND:
      return "Requested resource could not be found.";
    case ErrorTypeVariantEnum.METHOD_NOT_ALLOWED:
      return "Request had wrong request method.";
    case ErrorTypeVariantEnum.NOT_ACCEPTABLE:
      return "Could not satisfy the request Accept header.";
    case ErrorTypeVariantEnum.UNSUPPORTED_MEDIA_TYPE:
      return "Unsupported media type.";
    case ErrorTypeVariantEnum.THROTTLED:
      return "Request was throttled.";
    case ErrorTypeVariantEnum.API_REDIRECT_EXCEPTION:
      return defaultErrorMessage;
    case ErrorTypeVariantEnum.API_PERMANENT_REDIRECT_EXCEPTION:
      return defaultErrorMessage;
    case ErrorTypeVariantEnum.API_TEMPORARY_REDIRECT_EXCEPTION:
      return defaultErrorMessage;
    case ErrorTypeVariantEnum.NID_EXCEPTION:
      return defaultErrorMessage;
    case ErrorTypeVariantEnum.GATEWAY_ERROR:
      return "Connect to gateway failed.";
    default:
      return defaultErrorMessage;
  }
}
