import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "./customBaseQuery";

export default createApi({
  reducerPath: "appApi",
  tagTypes: [
    "Instance",
    "Users",
    "Customers",
    "Readerapp",
    "ReaderType",
    "Versions",
    "Extensions",
  ],
  baseQuery: customFetchBaseQuery,
  endpoints: () => ({}),
});
