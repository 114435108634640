import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import PageNotFound from "../../views/PageNotFound";
import { useAppSelector } from "../../hooks/storeHooks";
import { setUserAuth } from "../../redux/userSlice";

export function UserIsAdmin() {
  const { isAdmin, isLoggedIn } = useAppSelector(setUserAuth);
  if (isLoggedIn) {
    if (!isAdmin) {
      return <PageNotFound />;
    }
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

export function IsUserAuthenticated() {
  const { isLoggedIn } = useAppSelector(setUserAuth);
  if (isLoggedIn) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

export function IsUserUnAuthenticated() {
  const { isLoggedIn } = useAppSelector(setUserAuth);
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
