// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import { addDays, differenceInDays } from "date-fns";

export function calcLicenceExpiration(subExpiry: Date | undefined) {
  const daysUntillExp = addDays(new Date(), 30);

  if (subExpiry) {
    const daysUntillExpToTime = new Date(daysUntillExp).getTime();
    const expirationDateToTime = new Date(subExpiry).getTime();

    if (expirationDateToTime <= daysUntillExpToTime) {
      return differenceInDays(new Date(subExpiry), new Date());
    }
  }
  return null;
}

export const isOnProduction = () => process.env?.ON_PRODUCTION === "yes";

export function validateVersion(
  deployedTagName: string | undefined,
  formikAppTagName: string
) {
  const validateCode = deployedTagName?.localeCompare(formikAppTagName, "en", {
    sensitivity: "base",
  });

  if (validateCode === 1) {
    return "Warning! Dowgrading to lower version is dangerous.";
  }
  return "";
}
