// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { User } from "./api/api.types";

type UserState = {
  user: User | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
};

const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  isAdmin: false,
};

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    setUser: (
      state,
      action: PayloadAction<{
        user: User;
        isLoggedIn: boolean;
        isAdmin: boolean;
      }>
    ) => {
      state.user = action.payload.user;
      state.isLoggedIn = action.payload.isLoggedIn;
      state.isAdmin = action.payload.isAdmin;
    },
  },
});

export const { logout, setUser } = userSlice.actions;
export const setUserAuth = (state: RootState) => state.user;

export default userSlice.reducer;
